// JS file for the Landing (index.html) page

// Import Splide

import Splide from "@splidejs/splide";

// Testimonial section slider mount

// var testimonialSlider = new Splide(".testimonial-slider", {
//   type: "loop",
//   arrows: false,
//   pagination: false,
//   padding: "3rem",
//   perPage: 1,
//   pauseOnFocus: true,
//   pauseOnHover: true,
//   gap: "40px",
//   fixedWidth: "50rem",
//   autoplay: true,
//   interval: 5000,
//   focus: "center",
//   breakpoints: {
//     1100: {
//       padding: "1rem",
//       fixedWidth: "40rem",
//     },
//     900: {
//       fixedWidth: "30rem",
//     },
//     600: {
//       fixedWidth: "20rem",
//       gap: "10px",
//     },
//     400: {
//       fixedWidth: "18rem",
//     },
//     300: {
//       fixedWidth: "16rem",
//     },
//   },
// });

// testimonialSlider.mount();

// Message section slider mount

var messageSlider = new Splide(".message-slider", {
  type: "loop",
  arrows: false,
  pagination: false,
  padding: "10%",
  pauseOnFocus: true,
  pauseOnHover: true,
  autoWidth: true,
  gap: "3%",
  autoplay: true,
  interval: 5000,
  focus: "center",
  perMove: 1,
  breakpoints: {
    640: {
      padding: "3rem",
      gap: "20px",
      perPage: 1,
    },
  },
});

messageSlider.mount();

// Our Services pill button logic

document.addEventListener("DOMContentLoaded", function () {
  const pills = document.querySelectorAll(".pill");
  const cards = document.querySelectorAll(".our-services-card");

  function resetPillStyles() {
    pills.forEach((pill) => {
      // Reset styles to default for non-selected pills
      pill.style.color = ""; // Reset to default text color
      pill.style.backgroundColor = ""; // Reset to default background
    });
  }

  pills.forEach(function (pill) {
    pill.addEventListener("click", function () {
      const service = this.getAttribute("data-service");

      resetPillStyles(); // Reset styles for all pills

      // Apply custom styles and add selected class to clicked pill
      this.style.color = this.getAttribute("data-text-color");
      this.style.backgroundColor = this.getAttribute("data-pill-bg");

      // Hide all cards
      cards.forEach((card) => {
        card.style.display = "none";
      });

      // Show the card corresponding to the clicked pill
      document.querySelector(
        `.our-services-card[data-service="${service}"]`
      ).style.display = "flex";
    });
  });

  // Simulate a click on the first pill to show the first card on page load
  if (pills.length > 0) {
    pills[0].click();
  }
});

// Parallax code for Our Vision section cards

document.addEventListener("DOMContentLoaded", () => {
  const cardWraps = document.querySelectorAll(".our-vision-card-wrap");

  cardWraps.forEach((wrap) => {
    wrap.addEventListener("mousemove", (e) => {
      const card = wrap.querySelector(".our-vision-card");
      const rect = wrap.getBoundingClientRect();
      const mouseX = e.clientX - rect.left - rect.width / 2;
      const mouseY = e.clientY - rect.top - rect.height / 2;

      const rotateX = (mouseY / rect.height) * 20; // Tilt card up and down
      const rotateY = (mouseX / rect.width) * 20; // Tilt card left and right

      card.style.transform = `rotateY(${rotateY}deg) rotateX(${rotateX}deg)`;
    });

    wrap.addEventListener("mouseleave", () => {
      setTimeout(() => {
        const card = wrap.querySelector(".our-vision-card");
        card.style.transform = "rotateY(0deg) rotateX(0deg)";
      }, 350);
    });
  });
});

// Conference section slider mount

var conferenceSlider = new Splide(".conference-slider", {
  type: "loop",
  arrows: false,
  pagination: false,
  autoWidth: false,
  fixedWidth: "56rem",
  autoplay: false,
  focus: "center",
  perMove: 1,
  perPage: 1,
  breakpoints: {
    1100: {
      fixedWidth: "40rem",
    },
    900: {
      fixedWidth: "30rem",
    },
    600: {
      fixedWidth: "28rem",
    },
    500: {
      fixedWidth: "23rem",
    },
    430: {
      fixedWidth: "22rem",
    },
    400: {
      fixedWidth: "20rem",
    },
  },
});

conferenceSlider.mount();
